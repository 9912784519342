import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { CircularProgress } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Suspense
                fallback={
                    <div className="flex h-screen items-center justify-center">
                        <CircularProgress size={25} color="inherit" />
                    </div>
                }
            >
                <PersistGate loading={null} persistor={persistor}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <App />
                    </LocalizationProvider>
                </PersistGate>
            </Suspense>
        </Provider>
    </React.StrictMode>,
);
