import { createSlice } from '@reduxjs/toolkit';

interface InitialStateTypes {
    width: any;
}

const initialState: InitialStateTypes = {
    width: window.screen.width,
};

const screenSlice = createSlice({
    name: 'screen',
    initialState,
    reducers: {
        setScreenWidth: (state) => ({
            ...state,
            width: window.screen.width,
        }),
    },
});

export const selectScreen = (state: any) => state.screen;

export const { setScreenWidth } = screenSlice.actions;

export default screenSlice.reducer;
