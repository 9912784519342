import { createSlice } from '@reduxjs/toolkit';

interface InitialStateTypes {
    type: undefined | string;
    isOpen: boolean;
}

const initialState: InitialStateTypes = {
    type: undefined,
    isOpen: false,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        
        setModalType: (state, action) => ({
            ...state,
            type: action.payload,
        }),
    },
});

export const selectModal = (state: any) => state.modal;

export const { setModalType } = modalSlice.actions;

export default modalSlice.reducer;
