import { AlertColor } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';

interface InitialStateTypes {
    type: AlertColor | undefined;
    msg: string | undefined;
    isShown: boolean;
}

const initialState: InitialStateTypes = {
    type: undefined,
    msg: undefined,
    isShown: false,
};

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setAlert: (state, action) => ({
            ...state,
            type: action.payload.type,
            msg: action.payload.msg,
            isShown: action.payload.isShown,
        }),
    },
});

export const selectAlert = (state: any) => state.alert;

export const { setAlert } = alertSlice.actions;

export default alertSlice.reducer;
