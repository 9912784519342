import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './features/authSlice';
import alertSlice from './features/alertSlice';
import modalSlice from './features/modalSlice';
import loaderSlice from './features/loaderSlice';
import screenSlice from './features/screenSlice';

const rootReducer = combineReducers({
    auth: authSlice,
    alert: alertSlice,
    modal: modalSlice,
    loader: loaderSlice,
    screen: screenSlice,
});

export default rootReducer;
