import { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import { setScreenWidth } from 'redux/features';
import { selectAlert, setAlert } from 'redux/features/alertSlice';

const Routes = lazy(() => import('./routes'));

function App() {
    const dispatch = useDispatch();
    const alert = useSelector<any, any>(selectAlert);

    useEffect(() => {
        setTimeout(() => {
            dispatch(setAlert({ type: undefined, msg: undefined, isShown: false }));
        }, 3000);

        // HANDLES ALL DATA TABLE'S RESPONSIVENESS
        window.addEventListener('resize', () => {
            dispatch(setScreenWidth());
        });
    }, []);

    return (
        <>
            <Routes />
            {alert.msg && (
                <Snackbar
                    open={alert.isShown}
                    autoHideDuration={3000}
                    onClose={() => dispatch(setAlert({ ...alert, isShown: false }))}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Alert
                        severity={alert.type}
                        onClose={() => dispatch(setAlert({ ...alert, isShown: false }))}
                    >
                        {alert.msg}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
}

export default App;
